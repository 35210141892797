import { Override } from "../types";
import { ObjectEnum } from "../types/enums";
import { dateToStr, strToDate, WeekdayIndex } from "../utils/dates";
import { normalize } from "../utils/objects";
import { camelcase } from "../utils/strings";
import {
  DayHours as DayHoursDto,
  EntitlementDetails as EntitlementDetailsDto,
  Entitlements as EntitlementsDto,
  LocalTimeInterval as LocalTimeIntervalDto,
  ReclaimEdition as ReclaimEditionDto,
  ReferralStats as ReferralStatsDto,
  Settings as SettingsDto,
  ThinCalendar,
  ThinPerson as DtoThinPerson,
  TimePolicy as TimePolicyDto,
  User as UserDto,
  UserMetadata as UserMetadataDto,
  UserMetadataCompanySize as UserMetadataCompanySizeDto,
  UserMetadataUsecase as UserMetadataUsecaseDto,
  UserSettings as UserSettingsDto,
  UserTrait as UserTraitDto
} from "./client";
import { EventColor, PrimaryCategory } from "./EventMetaTypes";
import { TaskDefaults } from "./Tasks";
import { TransformDomain } from "./types";

export { DateFieldOrder } from './client';

export type EntitlementName = keyof EntitlementsDto;
export type EntitlementDetails<N> = Override<
  EntitlementDetailsDto,
  {
    name: N;
  }
>;
export type DetailedEntitlements = { [N in EntitlementName]: EntitlementDetails<N> };

export type ReferralStats = ReferralStatsDto;

export const LOGIN_BASE_URI = process.env.NEXT_PUBLIC_LOGIN_BASE_URI || "/oauth/login";
export const LOGOUT_URI = process.env.NEXT_PUBLIC_LOGOUT_URI || "/logout";

export type UserMetadataUsecase = `${UserMetadataUsecaseDto}`;
export type UserMetadataCompanySize = `${UserMetadataCompanySizeDto}`;
export type UserMetadata = Override<
  UserMetadataDto,
  {
    companySize?: UserMetadataCompanySize;
    usecase?: UserMetadataUsecase;
  }
>;

export class UserTrait extends ObjectEnum<UserTraitDto> {
  constructor(public readonly group: string, public readonly feature: string, public readonly label: string) {
    super([group, feature].join("_") as UserTraitDto);
  }
}
export class InterestTrait extends UserTrait {
  static Tasks = new InterestTrait("TASKS", "Tasks");
  static Priorities = new InterestTrait("PRIORITIES", "Priorities");
  static Office365 = new InterestTrait("OFFICE365", "Office365");
  static Calendar = new InterestTrait("CALENDAR", "Calendar");

  static Asana = new InterestTrait("INTEGRATION_ASANA", "Asana");
  static Trello = new InterestTrait("INTEGRATION_TRELLO", "Trello");
  static Todoist = new InterestTrait("INTEGRATION_TODOIST", "Todoist");
  static Jira = new InterestTrait("INTEGRATION_JIRA", "Jira");
  static Linear = new InterestTrait("INTEGRATION_LINEAR", "Linear");
  static ClickUp = new InterestTrait("INTEGRATION_CLICKUP", "ClickUp");
  static Monday = new InterestTrait("INTEGRATION_MONDAY", "Monday");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`INTEREST_${feature.toUpperCase()}`);
  }

  constructor(public readonly feature: string, public readonly label: string) {
    super("INTEREST", feature, label);
  }
}
export class OnboardTrait extends UserTrait {
  static Tasks = new OnboardTrait("TASKS", "Tasks");
  static GoogleTasks = new OnboardTrait("GOOGLE_TASKS", "Google Tasks");
  static PlanItemPrioritized = new OnboardTrait("PLAN_ITEM_PRIORITIZED", "Plan item prioritized");
  static SmartOneOnOne = new OnboardTrait("SMART_ONE_ON_ONES", "Smart 1:1s");
  static BufferTime = new OnboardTrait("BUFFER_TIME", "Buffer time");
  static TasksReindex = new OnboardTrait("TASKS_REINDEX", "Tasks reindex");
  // This whole thing tries to be WAY too clever with concats and converting snake-case to camel case
  // must specify dtoName here since the trait name is ONBOARD_GOOGLE_ADDON (no _ between ADD and ON)
  // and the onboarding feature object name is googleAddOn (capital O)
  static GoogleAddon = new OnboardTrait("GOOGLE_ADDON", "Google calendar add-on", "googleAddOn");

  static get(feature: string) {
    if (!feature) return undefined;
    return super.get(`ONBOARD_${feature.toUpperCase()}`);
  }

  static completed(user: User | null, feature: OnboardTrait) {
    return !!user?.features.onboard?.[feature.dtoName || camelcase(feature.feature)];
  }

  constructor(public readonly feature: string, public readonly label: string, public readonly dtoName?: string) {
    super("ONBOARD", feature, label);
  }
}

export type ThinPerson = DtoThinPerson;

export enum TimePolicyType {
  Work = "WORK",
  Personal = "PERSONAL",
  Meeting = "MEETING",
}

/**
 * Directly mapped to values in `ReclaimEdition` on the backend.
 * These limits are not currently available in the generated client, should be updated if/when it ever is.
 */
export type ReclaimEditionLimits = {
  assistWeeks: number;
  assistDaysForward: number;
  assistDaysBack: number;
  recurringDaysForward: number;
  maxDailyHabits: number;
};

// Lookup caches. Kept outside the class so they're not included in enumerable attrs
let reclaimEditionOptions: ReclaimEdition[];
let reclaimEditionsByIndex: Record<number, ReclaimEdition>;
let reclaimEditionsByKey: Record<ReclaimEditionDto, ReclaimEdition>;
export class ReclaimEdition extends ObjectEnum {
  static None = new ReclaimEdition(ReclaimEditionDto.NONE, "None", 0, {
    assistWeeks: 0,
    assistDaysForward: 0,
    assistDaysBack: 0,
    recurringDaysForward: 0,
    maxDailyHabits: 0,
  });
  static Trial = new ReclaimEdition(ReclaimEditionDto.TRIAL, "Trial", 1, {
    assistWeeks: 12,
    assistDaysForward: 30,
    assistDaysBack: 7,
    recurringDaysForward: 100,
    maxDailyHabits: Infinity,
  });
  static Free = new ReclaimEdition(ReclaimEditionDto.ASSISTANT, "Free", 1, {
    assistWeeks: 3,
    assistDaysForward: 30,
    assistDaysBack: 7,
    recurringDaysForward: 30,
    maxDailyHabits: 3,
  });
  // TODO (SS): Legacy should go away after 4/11/2022
  static LegacyProTrial = new ReclaimEdition(ReclaimEditionDto.LEGACY_PRO_TRIAL, "Pro Trial", 2, {
    assistWeeks: 12,
    assistDaysForward: 70,
    assistDaysBack: 7,
    recurringDaysForward: 100,
    maxDailyHabits: Infinity,
  });
  static Pro = new ReclaimEdition(ReclaimEditionDto.PRO, "Pro", 2, {
    assistWeeks: 8,
    assistDaysForward: 70,
    assistDaysBack: 7,
    recurringDaysForward: 70,
    maxDailyHabits: Infinity,
  });
  // TODO (SS): Legacy should go away after 4/11/2022
  static LegacyTeamTrial = new ReclaimEdition(ReclaimEditionDto.LEGACY_TEAM_TRIAL, "Team Trial", 3, {
    assistWeeks: 12,
    assistDaysForward: 100,
    assistDaysBack: 7,
    recurringDaysForward: 100,
    maxDailyHabits: Infinity,
  });
  static Team = new ReclaimEdition(ReclaimEditionDto.TEAM, "Team", 3, {
    assistWeeks: 12,
    assistDaysForward: 100,
    assistDaysBack: 7,
    recurringDaysForward: 100,
    maxDailyHabits: Infinity,
  });
  static Reward = new ReclaimEdition(ReclaimEditionDto.REWARD, "Reward", 3, {
    assistWeeks: 12,
    assistDaysForward: 100,
    assistDaysBack: 7,
    recurringDaysForward: 100,
    maxDailyHabits: Infinity,
  });

  static get Options(): ReclaimEdition[] {
    return reclaimEditionOptions;
  }

  static getByIndex(index: number) {
    return reclaimEditionsByIndex[index];
  }

  static getByKey(key: ReclaimEditionDto): ReclaimEdition {
    return reclaimEditionsByKey[key];
  }

  static forEach(callback: (edition: ReclaimEdition) => void, min?: ReclaimEdition, max?: ReclaimEdition): void {
    // no gaurentee that the index passed into constructor is real index in reclaimEditionOptions
    let minIndex: number | undefined = reclaimEditionOptions.indexOf(min as ReclaimEdition);
    let maxIndex: number | undefined = reclaimEditionOptions.indexOf(max as ReclaimEdition);
    minIndex = minIndex === -1 ? undefined : minIndex;
    maxIndex = maxIndex === -1 ? undefined : maxIndex;

    reclaimEditionOptions.slice(minIndex, maxIndex).forEach(callback);
  }

  static map<T>(callback: (edition: ReclaimEdition) => T, min?: ReclaimEdition, max?: ReclaimEdition): T[] {
    const arr: T[] = [];
    ReclaimEdition.forEach((edition) => arr.push(callback(edition)), min, max);
    return arr;
  }

  constructor(
    public readonly key: ReclaimEditionDto,
    public readonly label: string,
    public readonly index: number,
    public readonly limits: ReclaimEditionLimits
  ) {
    super(key);
  }
}
// Do the work once and set the caches
reclaimEditionOptions = Object.values(ReclaimEdition).sort((a, b) => a.index - b.index);
reclaimEditionsByIndex = normalize(ReclaimEdition.Options, "index");
reclaimEditionsByKey = normalize(ReclaimEdition.Options, "key");

export type Entitlements = Override<EntitlementsDto, {}>;

export type DayHours = DayHoursDto;
export type LocalTimeInterval = LocalTimeIntervalDto;
export type TimePolicy = Override<
  TimePolicyDto,
  {
    // startOfWeek?: DayOfWeek;
    // endOfWeek?: DayOfWeek;
    // dayHours: Record<DayOfWeek, DayHours>;
  }
>;

export type AssistSettings = Override<
  UserSettingsDto["assistSettings"],
  {
    travel?: boolean;
    otherTravelDuration?: number;
    conferenceBuffer?: boolean;
    conferenceBufferDuration?: number;
    conferenceBufferPrivate?: boolean;
    focus?: boolean;
  }
>;

export type SlackSettings = Override<
  UserSettingsDto["slackSettings"],
  {
    readonly enabled: boolean;
  }
>;

export type TaskSettings = Override<
  UserSettingsDto["taskSettings"],
  {
    readonly enabled: boolean;
    readonly googleTasks: boolean;
    defaults: TaskDefaults;
  }
>;

export type ProjectSettings = Override<
  UserSettingsDto["projects"],
  {
    readonly enabled: boolean;
  }
>;

export type PrioritiesSettings = Override<
  UserSettingsDto["priorities"],
  {
    readonly enabled: boolean;
  }
>;

export type ColorsSettings = Override<
  UserSettingsDto["colors"],
  {
    readonly enabled: boolean;
    readonly categoriesEnabled: boolean;
    readonly projectsEnabled: boolean;
    categories: Record<string, EventColor>;
    priorities: Record<string, EventColor>;
  }
>;

export type CalendarSettings = Override<
  UserSettingsDto["calendar"],
  {
    readonly enabled: boolean;
  }
>;

export type FocusSettings = Override<
  UserSettingsDto["focus"],
  {
    readonly enabled: boolean;
  }
>;

export type SyncSettings = Override<
  UserSettingsDto["sync"],
  {
    readonly enabled: boolean;
  }
>;

export type WeeklyReportSettings = Override<
  UserSettingsDto["weeklyReport"],
  {
    readonly enabled: boolean;
    sendReport?: boolean;
  }
>;

export type UserSettings = Override<
  // TODO: remove this Omit as soon as the backend has removed this flag
  Omit<UserSettingsDto, "asana">,
  {
    assistSettings: AssistSettings;
    slackSettings: SlackSettings;
    taskSettings: TaskSettings;
    projectSettings: ProjectSettings;
    priorities: PrioritiesSettings;
    colors: ColorsSettings;
    calendar: CalendarSettings;
    focus: FocusSettings;
    sync: SyncSettings;
    weeklyReport: WeeklyReportSettings;
    timePolicies: Record<string, TimePolicy>; // FIXME (IW): use TimePolicyType as key
  }
>;

export type Settings = Override<
  SettingsDto,
  {
    weekStart?: WeekdayIndex;
  }
>;

export type UserFeature = keyof UserSettings;

// TODO this is going to be kinda messy to maintain... to keep in sync with UserSettings (ma)
export type UserFeatureFlag = {
  modifyCalendar: boolean;
  assist: boolean;

  "defaultSyncSettings.workingHours": boolean;

  "assistSettings.focus": boolean;
  "assistSettings.travel": boolean;
  "assistSettings.conferenceBuffer": boolean;
  "assistSettings.conferenceBufferPrivate": boolean;

  "taskSettings.enabled": boolean;
  "slackSettings.enabled": boolean;
  "priorities.enabled": boolean;

  "colors.enabled": boolean;
  "colors.prioritiesEnabled": boolean;
  "colors.categoriesEnabled": boolean;
  "colors.projectsEnabled": boolean;

  "calendar.enabled": boolean;
  "focus.enabled": boolean;
  "billing.enabled": boolean;

  "asana.enabled": boolean;

  "appNotifications.enabled": boolean;
  "appNotifications.unscheduledPriority": boolean;

  "weeklyReport.sendReport": boolean;
};

export type UserTimezone = {
  id: string;
  displayName: string;
  abbreviation: string;
};

export type User = Override<
  UserDto,
  {
    readonly id: UserDto["id"];
    readonly name: string;
    readonly principal: UserDto["principal"];
    readonly provider: UserDto["provider"];
    readonly email: UserDto["email"];
    readonly timezone: UserTimezone;

    readonly trackingCode: UserDto["trackingCode"];
    readonly refCode: UserDto["refCode"];

    readonly admin?: UserDto["admin"];
    readonly likelyPersonal?: UserDto["likelyPersonal"];
    readonly apiKey?: UserDto["apiKey"];

    readonly created?: Date;
    readonly deleted?: Date;

    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly entitlements: Entitlements;
    readonly detailedEntitlements: DetailedEntitlements;

    readonly metadata: UserMetadata;

    features: UserSettings;
    primaryCalendar?: ThinCalendar | null;
    settings?: Settings;

    // TODO (ma): backend is generating incorrect types for response
    locale?: string;
  }
>;

function dtoToUser(dto: UserDto): User {
  const taskSettings: TaskSettings = {
    ...dto.features?.taskSettings,
    defaults: {
      ...dto.features?.taskSettings?.defaults,
      category: PrimaryCategory.get(dto.features?.taskSettings?.defaults?.category as unknown as string),
    },
  } as TaskSettings;

  const user: User = {
    ...dto,
    features: { ...(dto.features as unknown as UserSettings), taskSettings },
    created: strToDate(dto.created),
    deleted: strToDate(dto.deleted),
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial) || ReclaimEdition.Free,
  } as User;

  // colors
  if (!!dto.features?.colors) {
    const colors: Partial<ColorsSettings> = Object.entries(dto.features.colors).reduce((acc, [property, group]) => {
      if (typeof group === "object") {
        acc[property] = Object.entries<string>(group).reduce((acc2: Record<string, EventColor>, [color, value]) => {
          acc2[color] = EventColor.get(value);
          return acc2;
        }, {});
      }
      return acc;
    }, {});

    user.features.colors = { ...user.features.colors, ...colors };
  }

  return user;
}

function userToDto(user: Partial<User>): Partial<UserDto> {
  // TODO (IW): Fix nested types so this doesn't have to be casted as `any`
  const dto: Partial<UserDto> = {
    ...user,
    features: user.features as unknown as UserSettingsDto,
    created: dateToStr(user.created),
    deleted: dateToStr(user.deleted),
    // TODO (IW): Figure out readonly annotations, don't serialize readonly stuff
    locale: undefined,
    edition: user.edition?.key,
    editionAfterTrial: user.editionAfterTrial?.key,
    metadata: {
      ...user.metadata,
      companySize: user.metadata?.companySize && UserMetadataCompanySizeDto[user.metadata?.companySize],
      usecase: user.metadata?.usecase && UserMetadataUsecaseDto[user.metadata?.usecase],
    },
  };

  return dto;
}

export class UsersDomain extends TransformDomain<User, UserDto> {
  resource = "User";
  cacheKey = "users";
  pk = "id";

  public deserialize = dtoToUser;
  public serialize = userToDto;

  getCurrentUser = this.manageErrors(this.deserializeResponse(this.api.users.current));

  updateCurrentUser = this.manageErrors(
    this.deserializeResponse((user: Partial<User>) => this.api.users.patch3(this.serialize(user) as UserDto))
  );

  deleteCurrentUser = this.manageErrors(this.api.users.delete6);

  addInterest = this.manageErrors(
    this.deserializeResponse((trait: InterestTrait) => this.api.users.addTrait(trait.key, {}))
  );

  listContacts = this.manageErrors(this.api.users.getContacts);

  claimRewards: (tier: number) => Promise<ReferralStats> = this.manageErrors((tier) =>
    this.api.users.claimRewards({ claim: tier })
  );

  inviteContacts = this.manageErrors(this.api.users.inviteContacts);

  referrals = this.manageErrors(this.api.users.referrals);

  authRedirect(
    provider: string,
    hint?: string | false,
    state?: { [key: string]: string | null | number | undefined } | null
  ) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${LOGIN_BASE_URI}/${provider}`, window.location.href);
    if (state) authUrl.searchParams.append("state", JSON.stringify(state));

    if (false === hint) {
      authUrl.searchParams.append("prompt", "select_account");
    } else if (typeof hint === "string") {
      authUrl.searchParams.append("login_hint", hint);
    }

    window.location.href = authUrl.toString();
  }

  logout() {
    const url = new URL(LOGOUT_URI, window.location.href);
    window.location.href = url.toString();
  }
}
